<template>
  <div>
    <b-dropdown
      :text= "$t('Add condition')"
      variant="primary"
      class="mb-2"
    >
      <b-dropdown-item 
      v-for="(condition, index) in availableConditions" 
      :key="index" 
      @click="addComponent(condition.component)">
        {{condition.name}}
      </b-dropdown-item>
    </b-dropdown>

    <b-row v-show="!selectedConditions.length">
      <b-col>
        <b-card :title="$t('Προσθέστε συνθήκες')">
        {{ $t('Χρησιμοποιήστε το κουμπί πάνω αριστερά για να επιλέξετε υπό ποιές συνθήκες θέλετε να ενεργοποιείται η προσφορά σας.') }}
        </b-card>
      </b-col>
    </b-row>

    <template v-for="(selectedCondition, index) in selectedConditions">
      <component :is="selectedCondition.component" 
        :key="index"
        :ref="index"
        :conditionData="selectedCondition.props"
        @remove="removeComponent"></component>
    </template> 
  </div>
</template>

 <script>
 import conditionComponents from '../offers/conditionsIndex.js';
 import {
     BDropdown, BDropdownItem, BRow, BCol, BCard
  } from 'bootstrap-vue';  
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BDropdown,
    BDropdownItem,
    ToastificationContent,
    ...conditionComponents,
  },
  data(){
    return {
      availableConditions: [
        {name: this.$t('Category'), component: 'category-condition'},
        {name: this.$t('Product'), component: 'product-condition'},
        {name: this.$t('quantity'), component: 'quantity-condition'},
        {name: this.$t('Timespan'), component: 'timespan-condition'},
        {name: this.$t('Buyer'), component: 'buyer-condition'},
        {name: this.$t('area'), component: 'area-condition'},
        {name: this.$t('postalCode'), component: 'postal-code-condition'}
      ],
      selectedConditions: []
    }
  },
  props: {
    tabData: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    collectDataFromSelectedConditions(){
      let conditions = []
      this.selectedConditions.forEach((condition, index) => {
        conditions.push(...this.$refs[index][0].getData())
      });
      return conditions
    },
    canProceed(){
      if(this.selectedConditions.length > 0){
        let conditionValidationPromises = []
        this.selectedConditions.forEach((condition, index) => {
          conditionValidationPromises.push(this.$refs[index][0].isValid())
        });
        return Promise.allSettled(conditionValidationPromises).then((results) => {
          let errors = results.filter(result => result.status == "rejected")
          if(errors.length > 0){
            errors.forEach((error) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                    title: this.$t(error.reason),
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
              }) 
            })
            return false
          }
          this.$emit('add-step-data', this.$vnode.key, this.collectDataFromSelectedConditions())
          return true
        })
      }
      this.$toast({
        component: ToastificationContent,
        props: {
            title: this.$t('Please select at least one condition'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
        },
      })
      return false
    },
    addComponent(componentName, props){
      let condition = Object.assign({}, this.availableConditions.find((condition) => {return condition.component == componentName}))
      if(typeof props != 'undefined'){
        condition['props'] = props
      }
      this.selectedConditions.push(condition)
    },
    removeComponent(componentPos){
      this.selectedConditions.splice(componentPos, 1);
    }
  },
  created() {
    this.tabData.forEach((existingCondition) => {
      switch(existingCondition.type){
        case 'categories':
          this.addComponent('category-condition', existingCondition.configuration)
          break;
        case 'products':
          this.addComponent('product-condition', existingCondition.configuration)
          break;
        case 'quantity':
          this.addComponent('quantity-condition', existingCondition.configuration)
          break;
        case 'timespan':
          this.addComponent('timespan-condition', existingCondition.configuration)
          break;
        case 'buyers':
          this.addComponent('buyer-condition', existingCondition.configuration)
          break;
        case 'postalCodes':
          this.addComponent('postal-code-condition', existingCondition.configuration)
          break;
        case 'postalAreas':
          this.addComponent('area-condition', existingCondition.configuration)
          break;
      }
    })
  }
}
</script>