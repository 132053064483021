import DiscountAmountAction from '../actions/DiscountAmountAction.vue'
import DiscountPercentAction from '../actions/DiscountPercentAction.vue'
import DiscountPriceAction from '../actions/DiscountPriceAction.vue'
import PriceAction from '../actions/PriceAction.vue'

export default {
   DiscountAmountAction,
   DiscountPercentAction,
   DiscountPriceAction,
   PriceAction
}