<template>
  <b-row>
    <b-col>
      <b-card :title="$t('settings')">
        <b-form>
          <validation-observer ref="offerObserver">
            <b-row>
              <b-col lg="7">
                <b-form-group>
                  <!-- Active Checkbox -->
                  <b-form-checkbox v-model="settings.active">{{ $t('active') }}</b-form-checkbox>
                </b-form-group>   
                <b-form-group
                :label="$t('name')"
                label-for="v-name">
                  <!-- Name Input -->
                  <validation-provider #default="{ errors }" name="Name" rules="required">
                    <b-form-input id="v-name" v-model="settings.name" name="item-name" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                :label="$t('priority')"
                label-for="v-priority">
                  <!-- Priority Input -->
                  <b-form-input id="v-priority" type='number' min=-1000 max=1000 v-model="settings.priority" />
                </b-form-group> 
                <b-form-group>
                  <!-- Stop Propagation Checkbox -->
                  <b-form-checkbox class="inline-tooltip" v-model="settings.stopPropagation">{{ $t('stopPropagation') }}</b-form-checkbox> 
                  <feather-icon icon="InfoIcon" v-b-tooltip.hover.top.v-primary :title="$t('offer propagation tooltip')" size="20"/>
                </b-form-group>
                
              </b-col>
              
              <b-col lg="10">
                <b-tabs :class="{disableTabChange: isDefaultLanguageLabelSet}">
                  <b-tab v-for="language in languages" :key="language.locale" :title="$t(language.label)">
                    <div class="tab-text">
                      <template v-if="language.locale == defaultLanguage">
                        <b-form-group
                        :label="$t('label')"
                        label-for="v-language">
                          <validation-provider #default="{ errors }" name="Label" rules="required">
                            <b-form-input id="v-language" :label="$t('label')" v-model="label[language.locale]" />
                            <small class="text-danger text-sm">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        
                      </template>
                      <template v-else>
                        <b-form-group
                        :label="$t('label')"
                        label-for="v-language-non">
                          <b-form-input id="v-language-non" v-model="label[language.locale]" />
                        </b-form-group>
                      </template>
                    </div>
                  </b-tab>
                </b-tabs>
              </b-col>
              <!-- Language Tabs -->
              
            </b-row>
          </validation-observer>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { languageSettings } from '@/../appConfig.js'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@supplier-interface-validations'
import {
  BTabs, BTab, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BCard, BFormText, BSpinner, VBModal, BCardText, BOverlay,
  VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BTab,
    BTabs,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    BSpinner,
    BOverlay,
    BFormText,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    languageSettings,
    required
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {
      settings:{
        name: this.tabData.name,
        type: this.tabData.type,
        active: this.tabData.active,
        stopPropagation: this.tabData.stopPropagation,
        priority: this.tabData.priority
      },
      label: this.tabData.label,
      languages: languageSettings.languages,
      defaultLanguage: languageSettings.defaultLanguage,
    }
  },
  props: {
    tabData: {
      type: Object,
      default() {
        return {
          name: '',
          active: false,
          stopPropagation: false,
          priority: 0,
          label: (() => {
            let label = {}
            languageSettings.languages.forEach(language => {
              label[language.locale] = ""
            });
            return label;
          })()
        }
      }
    }
  },
  computed:{
    isDefaultLanguageLabelSet(){
      return this.label[this.defaultLanguage].length == 0
    }
  },
   methods:{
    canProceed(){
      return this.$refs.offerObserver.validate()
        .then((isValid) => {
          if(isValid){
            this.$emit('add-step-data', this.$vnode.key, Object.assign(this.settings, {label: this.label}))
          }
          return isValid
        });
    }
  },
}
</script>

<style scoped>
.disableTabChange >>> li {
  pointer-events: none;
  opacity: 0.5;
}

.someSpace{
  margin-bottom: 2px;
}
</style>