<template>
  <div>
    <b-dropdown
      :text= "$t('Actions')"
      variant="primary"
      class="mb-2"
    >
    <b-dropdown-item 
      v-for="(condition, index) in availableActions" 
      :key="index" 
      @click="addComponent(condition.component)">
        {{condition.name}}
      </b-dropdown-item>
    </b-dropdown>

    <b-row v-show="!selectedActions.length">
      <b-col>
        <b-card :title="$t('Προσθέστε ενέργειες')">
        {{ $t('Χρησιμοποιήστε το κουμπί πάνω αριστερά για να επιλέξετε τις ενέργειες που θα εκτελούνται όταν ισχύει η προσφορά.') }}
        </b-card>
      </b-col>
    </b-row>

     <template v-for="(selectedAction, index) in selectedActions"> 
      <component :is="selectedAction.component" 
        :key="index"
        :ref="index"
        :actionData="selectedAction.props"
        @remove="removeComponent"></component>
     </template>  
  </div>
</template>

<script>
import actionComponents from '../offers/actionsIndex.js';
import {
     BDropdown, BDropdownItem, BCard, BRow, BCol
  } from 'bootstrap-vue';  
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    ToastificationContent,
    ...actionComponents,
  },
  data(){
    return {      
      selectedActions: []
    }
  },
  computed:{
    availableActions(){
      if (this.$route.name.includes('offer')){
        return [
          {name: this.$t('Discount Amount'), component: 'discount-amount-action'},
          {name: this.$t('Discount Percent'), component: 'discount-percent-action'},
          {name: this.$t('Discount Price'), component: 'discount-price-action'},
          {name: this.$t('Price'), component: 'price-action'}
        ]
      }
      else {
        return [
          {name: this.$t('Discount Amount'), component: 'discount-amount-action'},
          {name: this.$t('Discount Percent'), component: 'discount-percent-action'}
        ]

      }
    }
  },
  props: {
    tabData: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    collectDataFromSelectedActions(){
      let actions = []
      this.selectedActions.forEach((action, index) => {actions.push(...this.$refs[index][0].getData())});
      return actions
    },
    canProceed(){
      if(this.selectedActions.length > 0){
        let actionValidationPromises = []
        this.selectedActions.forEach((action, index) => {
          actionValidationPromises.push(this.$refs[index][0].isValid())
        });
        return Promise.allSettled(actionValidationPromises).then((results) => {
          let errors = results.filter(result => result.status == "rejected")
          if(errors.length > 0){
            errors.forEach((error) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                    title: this.$t(error.reason),
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
              }) 
            })
            return false
          }
          this.$emit('add-step-data', this.$vnode.key, this.collectDataFromSelectedActions())
          return true
        })
      }
      this.$toast({
        component: ToastificationContent,
        props: {
            title:this.$t('Παρακαλώ επιλέξτε τουλάχιστον μία ενέργεια'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
        },
      })
      return false
    },
    addComponent(componentName, props){
      let action = Object.assign({}, this.availableActions.find((action) => {return action.component == componentName}))
      if(typeof props != 'undefined'){
        action['props'] = props
      }
      this.selectedActions.push(action)
    },
    removeComponent(componentPos){
      this.selectedActions.splice(componentPos, 1);
    }
  },
  created(){
    this.tabData.forEach((existingAction) => {
      switch(existingAction.type){
        case 'discountAmount':
          this.addComponent('discount-amount-action', existingAction.configuration)
          break;
        case 'discountPercent':
          this.addComponent('discount-percent-action', existingAction.configuration)
          break;
        case 'discountPrice':
          this.addComponent('discount-price-action', existingAction.configuration)
          break;
        case 'price':
          this.addComponent('price-action', existingAction.configuration)
          break;
      }
    })
  },
}
</script>