<template>
<b-overlay
  :show = "this.loading"
  spinner-variant="primary"
  rounded="sm"
 >
  <b-card-actions action-close action-collapse :title="$t('Product')"  v-on:close="removeSelf">    
    <b-row>
      <b-col class="w-full">
        <div class="m-2"> 
          <vue-good-table
            :key = 'keykey'
            ref = 'productsTable'
            :columns="columns"
            :rows="products"
            :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: $t('Επιλεγμένα Προϊόντα'),
              clearSelectionText: '',
              disableSelectInfo: false, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
            :sort-options="{
              enabled: true,
              initialSortBy: {field: 'id', type: 'asc'}
            }"
          >          
          </vue-good-table>
        </div> 
      </b-col>
    </b-row>
  </b-card-actions>
</b-overlay>
</template>

<script>
import {BCardActions} from '@core/components/b-card-actions'
import { ConditionsMixin } from './conditionsMixin.js'
import store from '@/store'
import moduleOffer from '@/store/offers/moduleOffer.js'
import {
     BCard, BCol, BRow, BButton, BFormGroup, BFormCheckboxGroup, BOverlay, BPagination
  } from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';

export default { 
   components: {
     
     BCardActions,
     BCard,
     BRow,
     BCol,
     BButton,
     BFormGroup, 
     BFormCheckboxGroup,
     BOverlay,
     BPagination,
     ...ConditionsMixin,
     ToastificationContent,
     moduleOffer,
     store,
     VueGoodTable
   },
  data() {
    return {
      loading: true,
      products: [],
      selected: this.conditionData.products,
      columns: [
        {label: 'ID',field: 'id',},
        {label: 'Name',field: 'name',},
        {label: 'SKU',field: 'sku',},
      ],
      keykey: 0,
      filter: null,
      perPage: 10,
      currentPage: 1,
      totalRows : 0,
      searchTerm : '',
    }
  },
  mixins: [ConditionsMixin],
  props: {
    conditionData: {
      type: Object,
      default() {
        return {
          products: []
        }
      }
    }
  },
  methods: {
    setSelected(){
      let sel =  this.products.map(x=>this.selected.includes(x["id"]))
      for (let i = 0; i < this.products.length; i++) {
        this.products[i]['vgtSelected'] = sel[i]       
      }
    },
    isValid(){
      return new Promise((resolve, reject) => {
        if(this.$refs['productsTable'].selectedRows.map(x=>x.id).length > 0){
          resolve(true)
        }
        reject(this.$t('Παρακαλώ επιλέξτε τουλάχιστον ένα προϊόν'))
      })
    },
    getData(){
        return [      
        this.formatData('products', {products: this.$refs['productsTable'].selectedRows.map(x=>x.id)})
      ]
    }
  },

  created(){

    if(!store.hasModule('offers')) {store.registerModule('offers', moduleOffer)}
    let p = this.$store.getters['offers/getProducts']
    if(p.length > 0){
      this.products = p.map(function(item){
        return {
          'id': item['id'].toString(),
          'name': item['name'],
          'sku': item['sku'],
          'vgtSelected':false
          }
      })
      this.loading = false
      this.setSelected()
      return;
    }
    this.$store.dispatch("offers/getProducts")
       .then( (products) => {      
          this.products = products.data.result.items.map(function(item){   
          return {
            'id': item['id'].toString(),
            'name': item['name'],
            'sku': item['sku'],
            'vgtSelected':false
          }
         })
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: this.$t("Error fetching products"),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
          },
        })
      })
      .finally(()=>{
        this.loading = false        
        this.setSelected()
        })
  },
}
</script>