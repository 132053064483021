<template>
  <b-card-actions action-close action-collapse :title="$t('quantity')"   v-on:close="removeSelf">     
    <b-row>
      <b-col class="w-full">

        <b-col sm="2">
          <label for="input-small">{{$t('Min Quantity')}}:</label>
          <b-form-input label="min quantity" type="number" v-model="minQuantity" class="mb-2" name="item-label" />
        </b-col>

        <b-col sm="2">
          <label for="input-small">{{$t('Max Quantity')}}:</label>
          <b-form-input label="max quantity" type="number" v-model="maxQuantity" class="mb-2"  name="item-label" />
        </b-col>
        
      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
import {BCardActions} from '@core/components/b-card-actions'
import { ConditionsMixin } from './conditionsMixin.js'
import store from '@/store'
import moduleOffer from '@/store/offers/moduleOffer.js'
import {
     BCard, BCol, BRow, BButton, BFormGroup, BFormCheckboxGroup, BOverlay, BFormInput
  } from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default { 
   components: {
     BCardActions,
     BCard,
     BRow,
     BCol,
     BButton,
     BFormGroup,    
     BFormInput,
     BFormCheckboxGroup,
     BOverlay,
     ...ConditionsMixin,
     ToastificationContent,
     moduleOffer,
     store
   },
  data() {
    return {
      minQuantity: this.conditionData.minQuantity,
      maxQuantity: this.conditionData.maxQuantity    
    }
  },
  mixins: [ConditionsMixin],
  props: {
    conditionData: {
      type: Object,
      default() {
        return {
          minQuantity: 0,
          maxQuantity: 0
        }
      }
    }
  },
  methods: {
        isValid(){
      return new Promise((resolve, reject) => {
        if(this.maxQuantity > 0 && parseInt(this.maxQuantity) < parseInt(this.minQuantity)){
          reject(this.$t('Η μέγιστη ποσότητα πρέπει να είναι μεγαλύτερη από την ελάχιστη'))
        }
        else if(this.minQuantity == 0){
          reject(this.$t('Η ελάχιστη ποσότητα πρέπει να είναι μεγαλύτερη από 0'))
        }
        resolve(true)
      })
    },
    getData(){
      return [
        this.formatData('quantity', {minQuantity: parseInt(this.minQuantity), maxQuantity: parseInt(this.maxQuantity)})
      ]
    }
  },
}
</script>