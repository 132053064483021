<template>
<b-overlay
  :show = "this.loading"
  spinner-variant="primary"
  rounded="sm"
 >
  <b-card-actions action-close action-collapse :title="$t('postalCode')"   v-on:close="removeSelf">    
    <b-row>
      <b-col class="w-full">
        <div class="m-2"> 
          <vue-good-table
            :key = "keykey"
            ref = 'postalCodesTable'
            :columns="columns"
            :rows="postCodes"
            :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: $t('Επιλεγμένοι Τ.Κ.'),
              clearSelectionText: '',
              disableSelectInfo: false, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
            :sort-options="{
              enabled: true,
            }"
            :pagination-options="{
              enabled: true,
              perPage:perPage
            }"
          >       



              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">         
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>




          </vue-good-table>
        </div> 
      </b-col>
    </b-row>
  </b-card-actions>
</b-overlay>
</template>

<script>
import {BCardActions} from '@core/components/b-card-actions'
import { ConditionsMixin } from './conditionsMixin.js'
import store from '@/store'
import moduleOffer from '@/store/offers/moduleOffer.js'
import {
     BCard, BCol, BRow, BButton, BFormGroup, BFormCheckboxGroup, BOverlay, BPagination
  } from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';    
import vSelect from 'vue-select'

export default { 
   components: {
     BCardActions,
     BCard,
     BRow,
     BCol,
     BButton,
     BFormGroup, 
     BFormCheckboxGroup,
     BOverlay,
     BPagination,
     vSelect,
     ...ConditionsMixin,
     ToastificationContent,
     moduleOffer,
     store,
     VueGoodTable
   },
  data() {
    return {
      loading: true,
      keykey: 0,
      columns: [
        {
          label: this.$t('ΤΚ'),
          field: 'postal_code',
        },
        {
          label: this.$t('Περιοχή'),
          field: 'postal_area',
        },
        {
          label: this.$t('Νομός'),
          field: 'state',
        },
      ],
      postCodes:[],
      selected: this.conditionData.postalCodes,
      filter: null,
      perPage: 10,
      currentPage: 1,
      totalRows : 0,
      searchTerm : '',
    }
  },
  mixins: [ConditionsMixin],
  props: {
    conditionData: {
      type: Object,
      default() {
        return {
          postalCodes: []
        }
      }
    }
  },
  methods: {
    setSelected(){
      let sel =  this.postCodes.map(x=>this.conditionData.postalCodes.includes(x["postal_code"]))
      for (let i = 0; i < this.postCodes.length; i++) {
        this.postCodes[i]['vgtSelected'] = sel[i]
      }     
    },
    isValid(){
      return new Promise((resolve, reject) => {
        if(this.$refs['postalCodesTable'].selectedRows.map(x=>x.id).length > 0){
          resolve(true)
        }
        reject(this.$t('Παρακαλώ επιλέξτε τουλάχιστον έναν ΤΚ'))
      })
    },
    getData(){
        return [      
        this.formatData('postalCodes', {postalCodes: this.$refs['postalCodesTable'].selectedRows.map(x=>x.postal_code)})
      ]
    }
  },

created(){

    if(!store.hasModule('offers')) {store.registerModule('offers', moduleOffer)}

    let states = this.$store.getters['offers/getPostalCodes']

    if(states.length > 0){   
      this.postCodes = states.map(function(item){
        return {
          'postal_code': item['postal_code'].toString(),
          'postal_area': item['postal_area'],
          'state': item['state']
          }
      })
      this.loading = false
      this.setSelected()
      return;
    }
    this.$store.dispatch("offers/getPostalCodes")
       .then( (data) => {
         this.postCodes = data.data.result.map(function(item){
            return {
              'postal_code': item['postal_code'].toString(),
              'postal_area': item['postal_area'],
              'state': item['state']
              }
          })
       })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: this.$t("Error fetching Post Codes"),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
          },
        })
      })
      .finally(()=>{
        this.loading = false 
        this.setSelected()
        this.keykey++
      })
  },
}
</script>