import CategoryCondition from '../conditions/CategoryCondition.vue'
import ProductCondition from '../conditions/ProductCondition.vue'
import QuantityCondition from '../conditions/QuantityCondition.vue'
import TimespanCondition from '../conditions/TimespanCondition.vue'
import BuyerCondition from '../conditions/BuyerCondition.vue'
import AreaCondition from '../conditions/AreaCondition.vue'
import PostalCodeCondition from '../conditions/PostalCodeCondition.vue'

export default {
  CategoryCondition,
  ProductCondition,
  QuantityCondition,
  TimespanCondition,
  BuyerCondition,
  AreaCondition,
  PostalCodeCondition,
}