var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":this.loading,"spinner-variant":"primary","rounded":"sm"}},[_c('b-card-actions',{attrs:{"action-close":"","action-collapse":"","title":_vm.$t('postalCode')},on:{"close":_vm.removeSelf}},[_c('b-row',[_c('b-col',{staticClass:"w-full"},[_c('div',{staticClass:"m-2"},[_c('vue-good-table',{key:_vm.keykey,ref:"postalCodesTable",attrs:{"columns":_vm.columns,"rows":_vm.postCodes,"select-options":{
              enabled: true,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: _vm.$t('Επιλεγμένοι Τ.Κ.'),
              clearSelectionText: '',
              disableSelectInfo: false, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            },"sort-options":{
              enabled: true,
            },"pagination-options":{
              enabled: true,
              perPage:_vm.perPage
            }},scopedSlots:_vm._u([{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.perPage,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value){ return props.pageChanged({currentPage:value}); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }