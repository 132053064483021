<template>
  <b-card-actions action-close action-collapse :title="$t('Discount Price')"  v-on:close="removeSelf">    

    <b-row>
      <b-col class="w-full">

        <b-col sm="2">
          <label for="input-small">{{$t('Discount Price')}}:</label>
          <b-form-input label="price" type="number" v-model="price" class="mb-2"  name="item-label" />  
        </b-col>

      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
import {BCardActions} from '@core/components/b-card-actions'
import { ActionsMixin } from './actionsMixin.js'
import {
     BCard, BCol, BRow, BButton, BFormGroup, BFormCheckboxGroup, BOverlay, BFormInput
  } from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default { 
   components: {
     BCardActions,
     BCard,
     BRow,
     BCol,
     BButton,
     BFormGroup,    
     BFormInput,
     BFormCheckboxGroup,
     BOverlay,
     ...ActionsMixin,
     ToastificationContent,
   },
  data() {
    return {
      price: this.actionData.price, 
    }
  },
  mixins: [ActionsMixin],
  props: {
    actionData: {
      type: Object,
      default() {
        return {
          price: 0,
        }
      }
    }
  },
  methods: {
      isValid(){
      return new Promise((resolve, reject) => {
        if(this.price <= 0){
          reject(this.$t('Η τιμή με έκπτωση πρέπει να είναι μεγαλύτερη από 0'))
        }
        resolve(true)
      })
    },
    getData(){
      return [
        this.formatData('discountPrice', {price: this.price})
      ]
    }
  },
}
</script>