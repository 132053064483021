<template>
  <b-card-actions action-close action-collapse :title="$t('Timespan')"  v-on:close="removeSelf">    
    <b-row>
      <b-col class="w-full">

        <b-col sm="2" >
          <label for="input-small">{{$t('from')}}:</label>
          <flat-pickr :placeholder="$t('Ημερομηνία έναρξης')" :config="configFromDateTimePicker" v-model="fromDateTime" @on-change="onFromChange" @on-close="onFromClose" class="mb-2"  name="item-label" />
        </b-col> 
        <b-col sm="2" >
          <label for="input-small">{{$t('to')}}:</label>
          <flat-pickr :placeholder="$t('Ημερομηνία λήξης')" :config="configToDateTimePicker" v-model="toDateTime"  @on-change="onToChange" @on-close="onToClose" class="mb-2"  name="item-label" />
        </b-col>
      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
import {BCardActions} from '@core/components/b-card-actions'
import { ConditionsMixin } from './conditionsMixin.js'
import store from '@/store'
import moduleOffer from '@/store/offers/moduleOffer.js'
import {
     BCard, BCol, BRow, BButton, BFormGroup, BFormCheckboxGroup, BFormInput
  } from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default { 
   components: {
     BCardActions,
     BCard,
     BRow,
     BCol,
     BButton,
     BFormGroup,    
     BFormInput,
     BFormCheckboxGroup,
     flatPickr,
     ...ConditionsMixin,
     ToastificationContent,
     moduleOffer,
     store
   },
  data() {
    return {
      fromDateTime: this.conditionData.dateFrom,
      toDateTime: this.conditionData.dateTo,
      configFromDateTimePicker: {
        minDate: null,
        maxDate: null,
        enableTime: true,
        dateFormat: 'd/m/Y H:i:S',
        time_24hr: true
      },
      configToDateTimePicker: {
        minDate: null,
        maxDate: null,
        enableTime: true,
        dateFormat: 'd/m/Y H:i:S',
        time_24hr: true
      }  
    }
  },
  mixins: [ConditionsMixin],
  props: {
    conditionData: {
      type: Object,
      default() {
        return {
          dateFrom: null,
          dateTo: null
        }
      }
    }
  },
  methods: {
    isValid(){
      return new Promise((resolve, reject) => {
        if(!this.fromDateTime){
          reject('Please select a fromDate')
        }
        else if(this.toDateTime && this.toDateTime <= this.fromDateTime){
          reject(this.$t('Η ημερομηνία λήξης πρέπει να είναι μετά την ημ. έναρξης'))
        }
        resolve(true)
      })
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configToDateTimePicker, 'minDate', dateStr)
    },
    onFromClose(selectedDates, dateStr, instance) {
      if(this.fromDateTime.length == 0){
        instance.clear()
      }
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromDateTimePicker, 'maxDate', dateStr)
    },
    onToClose(selectedDates, dateStr, instance) {
      if(this.toDateTime.length == 0){
        instance.clear()
      }
    },
    getData(){
      let dateFrom = flatpickr.parseDate(this.fromDateTime, this.configFromDateTimePicker.dateFormat)
      let dateTo = flatpickr.parseDate(this.toDateTime, this.configToDateTimePicker.dateFormat)
      return [
        this.formatData('timespan', {
          dateFrom: typeof dateFrom == 'object' ? dateFrom.getTime() : null, 
          dateTo: typeof dateTo == 'object' ? dateTo.getTime() : null
        })
      ]
    }
  },
}
</script>