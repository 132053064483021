var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":this.loading,"spinner-variant":"primary","rounded":"sm"}},[_c('b-card-actions',{attrs:{"action-close":"","action-collapse":"","title":_vm.$t('Product')},on:{"close":_vm.removeSelf}},[_c('b-row',[_c('b-col',{staticClass:"w-full"},[_c('div',{staticClass:"m-2"},[_c('vue-good-table',{key:_vm.keykey,ref:"productsTable",attrs:{"columns":_vm.columns,"rows":_vm.products,"select-options":{
              enabled: true,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: _vm.$t('Επιλεγμένα Προϊόντα'),
              clearSelectionText: '',
              disableSelectInfo: false, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            },"sort-options":{
              enabled: true,
              initialSortBy: {field: 'id', type: 'asc'}
            }}})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }