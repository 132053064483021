<template>
  <div>
    <form-wizard
      color="rgba(0,57,122, 0.95)"
      errorColor="rgba(0,57,122, 0.95)"
      :title="null"
      :subtitle="null"
      :nextButtonText="$t('next')"
      :backButtonText="$t('previous')"
      :finishButtonText="$t('submit')"
      @on-complete="formSubmitted"
    >
      <tab-content v-for="tab in visibleTabs"
        :key="tab.id"
        :title="tab.title"
        :icon="tab.icon"
        :before-change="() => {
          return canProceed(tab.id)
        }">
        <component :ref="tab.id" :key="tab.id" :tabData="tab.props" @add-step-data="addStepData" :is="tab.component"></component>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import backend_api from '@/http/requests/backend-api/index.js'
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BCard, BFormText, BSpinner, VBModal, BCardText, BOverlay
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import StepSettings from '../steps/StepSettings.vue'
import StepConditions from '../steps/StepConditions.vue'
import StepActions from '../steps/StepActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  
  components: {
    StepSettings,
    StepConditions,
    StepActions,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    BSpinner,
    BOverlay,
    ValidationObserver,
    ValidationProvider,
    BFormText,
    BCardText,
    ToastificationContent,
    FormWizard,
    TabContent,
  },
  data() {
    return {
      id: this.$route.params.id,
      pricelistData: {
        settings: {},
        conditions: [],
        actions: []
      },
      tabs: []
    }
  },
  computed:{
    visibleTabs(){
      return this.tabs.filter((item) => { 
        return item.hide == false
      });
    },
  },
  methods: {
    fillPricelistDataFromPricelist(pricelist) {
      this.pricelistData.settings = pricelist.settings
      this.pricelistData.conditions = pricelist.conditions
      this.pricelistData.actions = pricelist.actions
    },
    initTabs(){
      this.tabs = [
        {id:'settings', title: this.$t('step') + ' 1', icon: '', component: 'step-settings', hide: false, props:this.pricelistData.settings},
        {id:'conditions', title: this.$t('step') + ' 2', icon: '', component: 'step-conditions', hide: false, props: this.pricelistData.conditions}, 
        {id:'actions', title: this.$t('step') + ' 3', icon: '', component: 'step-actions', hide: false, props: this.pricelistData.actions},
      ]
    },
    formSubmitted() {    
      backend_api.editProductPriceRule(this.id,this.pricelistData).then(
        (response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
                title: this.$t("Price rule edited!"),
                icon: 'CheckIcon',
                variant: 'success',
            },
          })
          this.$router.push({name: 'marketing-pricelists'})
        }
      )
      .catch( (error) => {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: this.$t('An error has occured'), 
              icon: 'TriangleIcon',
              variant: 'danger',
          },
        })
      })
    },
    canProceed(tabId){
      return this.$refs[tabId][0].canProceed()
    },
    addStepData(tabId, tabData){
      switch(tabId){
        case 'settings':
          this.pricelistData.settings = tabData;
          this.pricelistData.settings.type = 2;
          break;
        case 'conditions':
          this.pricelistData.conditions = tabData;
          break;
        case 'actions':
          this.pricelistData.actions = tabData;
          break;
      }
    }
  },
  created() {

      if (!this.$store.hasModule('productPriceRules')) this.$store.registerModule('productPriceRules', moduleProductPriceRules)
          
      let pricelist = this.$store.getters['productPriceRules/pricelist'](this.id)
  
      if(typeof pricelist != "undefined"){
        this.fillPricelistDataFromPricelist(pricelist)
        this.initTabs()
      }
      else{
        this.loading = true;
        this.$store.dispatch("productPriceRules/getProductPriceRule", this.id)
        .then( (pricelist) => {
          this.fillPricelistDataFromPricelist(pricelist)
          this.initTabs()
        })
        .catch( () => {
        this.$toast({
            component: ToastificationContent,
            props: {
                title: this.$t("Error fetching Pricelist Data"),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
            },
        })
      })
        .finally(()=>{
          this.loading = false
        })
    }
  },
}
</script>

<style lang="scss">
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>

 <style lang="scss" scoped>
.vue-form-wizard ::v-deep {
  .wizard-card-footer{
    .wizard-btn {  
      border-radius: 28px;
      background-image: linear-gradient(127.82deg,#1aade4 14.67%,#8000ff 85.61%);
      border: none !important;
      box-shadow: none !important;
      font-size: 16px;
    }
  }
}
</style>