<template>
<b-overlay
  :show = "this.loading"
  spinner-variant="primary"
  rounded="sm"
 >
  <b-card-actions  action-close action-collapse :title="$t('area')" header-tag="header" v-on:close="removeSelf">     
    <b-row>
      <b-col class="w-full">
        <div class="m-2"> 
          <vue-good-table
            :key = 'keykey'
            ref = 'areasTable'
            :columns="columns"
            :rows="areas"
            :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: $t('Επιλεγμένες Περιοχές'),
              clearSelectionText: '',
              disableSelectInfo: false, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
            :sort-options="{
              enabled: true,
              initialSortBy: {field: 'postal_state', type: 'asc'}
            }"
            :pagination-options="{
              enabled: true,
              perPage:perPage
            }"
          >       



          <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">         
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>




          </vue-good-table>
        </div> 
      </b-col>
    </b-row>
  </b-card-actions>
</b-overlay>
</template>

<script>
import {BCardActions} from '@core/components/b-card-actions'
import { ConditionsMixin } from './conditionsMixin.js'
import store from '@/store'
import moduleOffer from '@/store/offers/moduleOffer.js'
import {
     BCard, BCol, BRow, BButton, BFormGroup, BFormCheckboxGroup, BOverlay, BPagination
  } from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';    
import vSelect from 'vue-select'
import { lastIndexOf } from 'postcss-rtl/lib/affected-props';

export default { 
   components: {
     BCardActions,
     BCard,
     BRow,
     BCol,
     BButton,
     BFormGroup, 
     BFormCheckboxGroup,
     BOverlay,
     BPagination,
     vSelect,
     ...ConditionsMixin,
     ToastificationContent,
     moduleOffer,
     store,
     VueGoodTable
   },
  data() {
    return {
      loading: true,
      selected:this.conditionData.postalAreas,
      areas: [],
      columns: [   
        {label: this.$t('Περιοχή'),field: 'postal_area',},
        {label: this.$t('Νομός'),field: 'postal_state',},
      ],
      keykey: 0,
      filter: null,
      perPage: 10,
      currentPage: 1,
      totalRows : 0,
      searchTerm : '',
    }
  },
  mixins: [ConditionsMixin],
  props: {
    conditionData: {
      type: Object,
      default() {
        return {
          postalAreas: []
        }
      }
    }
  },
  methods: {
    setSelected(){
      let sel =  this.areas.map(x=>this.selected.includes(x["id"]))
      for (let i = 0; i < this.areas.length; i++) {
        this.areas[i]['vgtSelected'] = sel[i]       
      }
    },
    isValid(){
      return new Promise((resolve, reject) => {
        if(this.$refs['areasTable'].selectedRows.map(x=>x.id).length > 0){
          resolve(true)
        }
        reject(this.$t('Παρακαλώ επιλέξτε τουλάχιστον μία περιοχή'))
      })
    },
    getData(){
        return [      
        this.formatData('postalAreas', {postalAreas: this.$refs['areasTable'].selectedRows.map(x=>x.id)})
      ]
    }
  },

  created(){

    if(!store.hasModule('offers')) {store.registerModule('offers', moduleOffer)}


    let states = this.$store.getters['offers/getAreas'].map(x=>x['name'])

    if(states.length > 0){   
      let areas = this.$store.getters['offers/getAreas'].map(x=>x['postal_areas']).map(item=>(item.map(y=>y['name'])))
      let areaIds = this.$store.getters['offers/getAreas'].map(x=>x['postal_areas']).map(item=>(item.map(y=>y['id'])))
      let areasItem =[]
      let areaIdsItem =[]
      let statesItem = []
      areas.forEach(function(x,index)
      {
        areasItem.push(...x)
        for (let i = 0; i < x.length; i++) {
          statesItem.push(states[index])
        }        
      })
      areaIds.forEach(x=>areaIdsItem.push(...x))
          
      this.areas = areasItem.map(function(item,index){return {postal_state:statesItem[index],postal_area:item,id:areaIdsItem[index].toString()}})
      this.loading = false
      this.setSelected()
      return;
    }
    this.$store.dispatch("offers/getAreas")
       .then( (data) => {
         let areas = data.data.result.map(x=>x['postal_areas']).map(item=>(item.map(y=>y['name'])))
         let areaIds = data.data.result.map(x=>x['postal_areas']).map(item=>(item.map(y=>y['id'])))
         let states = data.data.result.map(x=>x['name'])
         
         let areasItem =[]
         let areaIdsItem = []
         let statesItem = []

         areas.forEach(function(x,index)
          {
            areasItem.push(...x)
            for (let i = 0; i < x.length; i++) {
              statesItem.push(states[index])             
            }        
          })
          areaIds.forEach(x=>areaIdsItem.push(...x))
          
          this.areas = areasItem.map(function(item,index){return {postal_state:statesItem[index],postal_area:item,id:areaIdsItem[index].toString()}})

      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: this.$t("Error fetching areas"),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
          },
        })
      })
      .finally(()=>{
        this.loading = false
        this.setSelected()
        this.keykey++
      })
  },
}
</script>