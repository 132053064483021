export let ConditionsMixin = {
    methods: {
      removeSelf(){
        this.$emit('remove', this.$vnode.key);
      },
      formatData(type, configuration){
        return {
          type,
          configuration
        }
      }
    }
  }