<template>
  <b-card-actions action-close action-collapse :title="$t('Buyer')"  v-on:close="removeSelf">   
    <b-row>
      <b-col class="w-full">
        <b-col sm="6">
          <label for="input-small">{{$t('taxId')}} ({{$t('χωρισμένα με κόμμα')}}):</label>
          <b-form-input label="Tax IDs" type="text" v-model="buyers" class="sm:w-1/4" name="item-selected-tax-id" />
        </b-col> 
      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
import {BCardActions} from '@core/components/b-card-actions'
import { ConditionsMixin } from './conditionsMixin.js'
import store from '@/store'
import moduleOffer from '@/store/offers/moduleOffer.js'
import {
     BCardText, BCol, BRow, BButton, BFormGroup, BFormCheckboxGroup, BOverlay, BFormInput
  } from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default { 
   components: {
     BCardActions,
     BCardText,
     BRow,
     BCol,
     BButton,
     BFormGroup,    
     BFormInput,
     BFormCheckboxGroup,
     BOverlay,
     flatPickr,
     ...ConditionsMixin,
     ToastificationContent,
     moduleOffer,
     store
   },
   data() {
    return {
      buyers: Object.values(this.conditionData.buyers).join(','),
    }
  },
  mixins: [ConditionsMixin],
  props: {
    conditionData: {
      type: Object,
      default() {
        return {
          buyers: null
        }
      }
    }
  },
  
  methods: {
    isValid(){
      return new Promise((resolve, reject) => {
        if(!this.buyers){
          reject(this.$t('Παρακαλώ πληκτρολογήστε τουλάχιστον ένα ΑΦΜ'))
        }
        resolve(true)
      })
    },
    getData(){
      return [
        this.formatData('buyers', {buyers: this.buyers})
      ]
    }
  },
}
</script>