<template>
  <b-card-actions action-close action-collapse :title="$t('Discount Percent')"  v-on:close="removeSelf">    

    <b-row>
      <b-col class="w-full">

        <b-col sm="2">
          <label for="input-small">{{$t('Discount Percent')}}:</label>
          <b-form-input label="percent" type="number" v-model="percent" class="mb-2"  name="item-label" />
        </b-col>

      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
import {BCardActions} from '@core/components/b-card-actions'
import { ActionsMixin } from './actionsMixin.js'
import {
     BCard, BCol, BRow, BButton, BFormGroup, BFormCheckboxGroup, BOverlay, BFormInput
  } from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default { 
   components: {
     BCardActions,
     BCard,
     BRow,
     BCol,
     BButton,
     BFormGroup,    
     BFormInput,
     BFormCheckboxGroup,
     BOverlay,
     ...ActionsMixin,
     ToastificationContent,
   },
  data() {
    return {
      percent: this.actionData.percent, 
    }
  },
  mixins: [ActionsMixin],
  props: {
    actionData: {
      type: Object,
      default() {
        return {
          percent: 0,
        }
      }
    }
  },
  methods: {
      isValid(){
      return new Promise((resolve, reject) => {
        if(this.percent <= 0){
          reject(this.$t('Το ποσοστό έκπτωσης πρέπει να είναι μεγαλύτερο από 0'))
        }   
        else if(this.percent > 100){
          reject(this.$t('Το ποσοστό έκπτωσης πρέπει να είναι μικρότερο από 100%'))
        }
        resolve(true)
      })
    },
    getData(){
      return [
        this.formatData('discountPercent', {percent: this.percent})
      ]
    }
  },
}
</script>