<template>
<b-overlay
  :show = "this.loading"
  spinner-variant="primary"
  rounded="sm"
 >
  <b-card-actions action-close action-collapse :title="$t('Category')"  v-on:close="removeSelf">    
    <b-row>
      <b-col class="w-full">
        <b-form-group>
          <b-form-checkbox-group
            id="categories-checkbox"
            v-model="selected"
            :options="categories"
            name="categs"
          ></b-form-checkbox-group>
        </b-form-group>
      </b-col>
    </b-row>
  </b-card-actions>
</b-overlay>
</template>

<script>
import {BCardActions} from '@core/components/b-card-actions'
import { ConditionsMixin } from './conditionsMixin.js'
import store from '@/store'
import moduleOffer from '@/store/offers/moduleOffer.js'
import {
     BCard, BCol, BRow, BButton, BFormGroup, BFormCheckboxGroup, BOverlay
  } from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default { 
   components: {
     BCardActions,
     BCard,
     BRow,
     BCol,
     BButton,
     BFormGroup, 
     BFormCheckboxGroup,
     BOverlay,
     ...ConditionsMixin,
     ToastificationContent,
     moduleOffer,
     store
   },
  data() {
    return {
      loading: true,
      categories: [],
      selected:this.conditionData.categories,
    }
  },
  mixins: [ConditionsMixin],
  props: {
    conditionData: {
      type: Object,
      default() {
        return {
          categories: []
        }
      }
    }
  },
  methods: {
    isValid(){
      return new Promise((resolve, reject) => {
        if(this.selected.length > 0){
          resolve(true)
        }
        reject(this.$t('Παρακαλώ επιλέξτε τουλάχιστον μία κατηγορία'))
      })
    },
    getData(){
      return [
        this.formatData('categories', {categories: this.selected})
      ]
    }
  },

  created(){

    if(!store.hasModule('offers')) {store.registerModule('offers', moduleOffer)}
    let pc = this.$store.getters['offers/getProductCategories']
    if(pc.length > 0){
      this.categories = pc.map(x=>x.name)
      this.loading = false
      return;
    }
    this.$store.dispatch("offers/getProductCategories")
      .then( (categories) => {
        this.categories = categories.data.result.map(x=>x.name)
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: this.$t("Error fetching categories"),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
          },
        })
      })
      .finally(()=>{this.loading = false})
  },
}
</script>